import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Divider, Tabs, message, Row, Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { w3cwebsocket } from 'websocket';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import styled from 'styled-components';

import Admin from '../components/coin/admin';
import Setting from '../components/coin/setting';
import UpbitOrder from '../components/coin/upbitOrder';
import BinanceOrder from '../components/coin/binanceOrder';
import BinanceFuturesOrder from '../components/coin/binanceFuturesOrder';
import BinanceCoinmOrder from '../components/coin/binanceCoinmOrder';
import UpbitTriggers from '../components/coin/upbitTriggers';
import BinanceTriggers from '../components/coin/binanceTriggers';
import BinanceFuturesTriggers from '../components/coin/binanceFuturesTriggers';
import BinanceCoinmTriggers from '../components/coin/binanceCoinmTriggers';
import PremiumTable from '../components/coin/premiumTable';
import CoinChart from '../components/coin/chart';

import { signOutAction } from '../modules/auth';
import { getUpbitMarketsAction, getUpbitWalletAction, getBinanceSymbolsAction, getBinanceWalletAction, getBinanceFuturesSymbolsAction, getBinanceFuturesWalletAction, getBinanceCoinmSymbolsAction, getBinanceCoinmWalletAction } from '../modules/coin';
import * as coinAPI from '../api/coin';
import useInterval from '../hooks/useInterval';

const ScrollCol = styled(Col)`
	max-height: 100vh;
	overflow-y: scroll;
`;
const { confirm } = Modal;
const { TabPane } = Tabs;

function CoinContainer() {
	const { auth, markets, upbitWallet, symbols, futuresSymbols, coinmSymbols, binanceWallet, binanceFuturesWallet, binanceCoinmWallet } = useSelector(({ authReducer, coinReducer }) => ({
		auth: authReducer.auth.data,
		markets: coinReducer.markets.data,
		upbitWallet: coinReducer.upbitWallet.data,
		symbols: coinReducer.symbols.data,
		futuresSymbols: coinReducer.futuresSymbols.data,
		coinmSymbols: coinReducer.coinmSymbols.data,
		binanceWallet: coinReducer.binanceWallet.data,
		binanceFuturesWallet: coinReducer.binanceFuturesWallet.data,
		binanceCoinmWallet: coinReducer.binanceCoinmWallet.data
	}));
	const dispatch = useDispatch();

	const [upbitWebSocketTicker, setUpbitWebSocketTicker] = useState(null);
	const [isUpbitWebSocketTickerRunning, setIsUpbitWebSocketTickerRunning] = useState(false);
	const [upbitTicker, setUpbitTicker] = useState({});
	const [upbitSetting, setUpbitSetting] = useState(null);
	const [upbitTriggers, setUpbitTriggers] = useState([]);
	const [upbitTriggerValue, setUpbitTriggerValue] = useState(null);
	const [binanceWebSocketTicker, setBinanceWebSocketTicker] = useState(null);
	const [binanceTicker, setBinanceTicker] = useState({});
	const [binanceSetting, setBinanceSetting] = useState(null);
	const [binanceTriggers, setBinanceTriggers] = useState([]);
	const [binanceTriggerValue, setBinanceTriggerValue] = useState(null);
	const [binanceFuturesTriggers, setBinanceFuturesTriggers] = useState([]);
	const [binanceFuturesTriggerValue, setBinanceFuturesTriggerValue] = useState(null);
	const [binanceCoinmTriggers, setBinanceCoinmTriggers] = useState([]);
	const [binanceCoinmTriggerValue, setBinanceCoinmTriggerValue] = useState(null);
	const [premiumOrders, setPremiumOrders] = useState([]);
	const [premiumTriggers, setPremiumTriggers] = useState([]);
	const [exchangeRate, setExchangeRate] = useState(1000);

	const baseUrl = `https://${process.env.REACT_APP_REST_API_ENDPOINT}.execute-api.ap-northeast-2.amazonaws.com/dev`;
	// const baseUrl = process.env.REACT_APP_NODE_ENV === 'dev' ? 'http://localhost' : auth.attributes['custom:baseURL'];
	// const baseUrl = process.env.REACT_APP_NODE_ENV === 'dev' ? 'http://ec2-3-36-250-19.ap-northeast-2.compute.amazonaws.com' : auth.attributes['custom:baseURL'];
	// const baseUrl = process.env.REACT_APP_NODE_ENV === 'dev' ? 'http://ec2-15-165-125-119.ap-northeast-2.compute.amazonaws.com' : auth.attributes['custom:baseURL'];

	useEffect(async () => {
		const expiryDays = moment(auth.attributes['custom:expiry']).endOf('day').diff(moment(), 'days');
		if (expiryDays > 0 && expiryDays <= 7) message.warning(`프리미엄이 ${expiryDays}일 후 종료됩니다.`, 0);
		confirm({
			title: 'AT YOUR OWN RISK',
			icon: <ExclamationCircleOutlined />,
			content: 'This is not a commercial software. Developer does not have any legar responsibility. If you agree with this then click OK.',
			onOk() {
				message.info('안녕하세요? 코라밸입니다~ ^_^');
			},
			onCancel() {
				message.info('안녕하가세요!');
				dispatch(signOutAction());
			}
		});
		dispatch(getUpbitMarketsAction());
		dispatch(getBinanceSymbolsAction());
		dispatch(getBinanceFuturesSymbolsAction());
		dispatch(getBinanceCoinmSymbolsAction());
		handleGetExchangeRate();

		const _upbitSetting = await coinAPI.getStore({ auth, baseUrl, key: 'upbitSetting' });
		if (_upbitSetting) setUpbitSetting(_upbitSetting);
		const _binanceSetting = await coinAPI.getStore({ auth, baseUrl, key: 'binanceSetting' });
		if (_binanceSetting) setBinanceSetting(_binanceSetting);

		const _upbitTriggers = await coinAPI.getStore({ auth, baseUrl, key: 'upbitTriggers' });
		setUpbitTriggers(_upbitTriggers || []);
		const _binanceTriggers = await coinAPI.getStore({ auth, baseUrl, key: 'binanceTriggers' });
		setBinanceTriggers(_binanceTriggers || []);
		const _binanceFuturesTriggers = await coinAPI.getStore({ auth, baseUrl, key: 'binanceFuturesTriggers' });
		setBinanceFuturesTriggers(_binanceFuturesTriggers || []);
		const _binanceCoinmTriggers = await coinAPI.getStore({ auth, baseUrl, key: 'binanceCoinmTriggers' });
		setBinanceCoinmTriggers(_binanceCoinmTriggers || []);
		const _premiumOrders = await coinAPI.getStore({ auth, baseUrl, key: 'premiumOrders' });
		setPremiumOrders(_premiumOrders || []);
		const _premiumTriggers = await coinAPI.getStore({ auth, baseUrl, key: 'premiumTriggers' });
		setPremiumTriggers(_premiumTriggers || []);
	}, []);

	const arrMarketsRef = useRef([]);
	useEffect(() => {
		if (markets) {
			setTimeout(() => {
				setUpbitWebSocketTicker(new w3cwebsocket('wss://api.upbit.com/websocket/v1'));
			}, 10000); // 10초 (10000ms) 뒤 실행
		}
	}, [markets]);

	useEffect(() => {
		if (upbitWebSocketTicker) {
			upbitWebSocketTicker.binaryType = 'arraybuffer';
			const _arrMarkets = [];
			for (const market of markets) {
				_arrMarkets.push(market.market);
			}
			arrMarketsRef.current = _arrMarkets;
			upbitWebSocketTicker.onopen = (e) => {
				upbitWebSocketTicker.send(JSON.stringify([{ ticket: uuidv4() }, { type: 'ticker', codes: arrMarketsRef.current, isOnlySnapshot: true, isOnlyRealtime: false }, { format: 'SIMPLE' }]));
				setIsUpbitWebSocketTickerRunning(true);
			};
			let websocketCounter = 0;
			let _upbitTicker = {};
			upbitWebSocketTicker.onmessage = (e) => {
				const enc = new TextDecoder('utf-8');
				const arr = new Uint8Array(e.data);
				const ticker = JSON.parse(enc.decode(arr));
				if (websocketCounter < arrMarketsRef.current.length) {
					websocketCounter++;
					_upbitTicker[ticker.cd] = ticker.tp;
				} else {
					setUpbitTicker(_upbitTicker);
					websocketCounter = 0;
					_upbitTicker = {};
				}
			};
		}
	}, [upbitWebSocketTicker]);

	useInterval(
		() => {
			if (upbitWebSocketTicker.readyState === 1) {
				upbitWebSocketTicker.send(JSON.stringify([{ ticket: uuidv4() }, { type: 'ticker', codes: arrMarketsRef.current, isOnlySnapshot: true, isOnlyRealtime: false }, { format: 'SIMPLE' }]));
			} else {
				console.log('reconnect upbit!!!!');
				setUpbitWebSocketTicker(new w3cwebsocket('wss://api.upbit.com/websocket/v1'));
			}
			if (binanceWebSocketTicker.readyState !== 1) setBinanceWebSocketTicker(new w3cwebsocket(`wss://fstream.binance.com/ws/!miniTicker@arr`));
		},
		isUpbitWebSocketTickerRunning ? 1000 : null
	);

	const arrSymbols = useRef([]);
	useEffect(() => {
		if (futuresSymbols) setBinanceWebSocketTicker(new w3cwebsocket(`wss://fstream.binance.com/ws/!miniTicker@arr`));
	}, [futuresSymbols]);

	useEffect(() => {
		if (binanceWebSocketTicker) {
			const _arrSymbols = [];
			for (const symbol of futuresSymbols) {
				_arrSymbols.push(symbol.symbol);
			}
			arrSymbols.current = _arrSymbols;
			binanceWebSocketTicker.onmessage = (e) => {
				if (typeof e.data === 'string') {
					const tickers = JSON.parse(e.data).filter((x) => arrSymbols.current.includes(x.s));
					const _tickers = {};
					for (const ticker of tickers) {
						_tickers[ticker.s] = ticker.c;
					}
					setBinanceTicker((prevState) => ({
						...prevState,
						..._tickers
					}));
				}
			};
		}
	}, [binanceWebSocketTicker]);

	const handleGetExchangeRate = () => {
		coinAPI.getExchangeRate().then((response) => setExchangeRate(response || 1000));
	};

	const handleSetExchangeRate = (value) => {
		setExchangeRate(Number(value) || 1000);
	};

	const handleSetUpbitSetting = (values) => {
		coinAPI.setUpbitSetting({ auth, baseUrl, values }).then((response) => {
			if (response) {
				setUpbitSetting(values);
				coinAPI.serverRestart({ auth, baseUrl });
			}
		});
	};

	const handleGetUpbitWallet = () => {
		if (upbitSetting) dispatch(getUpbitWalletAction({ auth, baseUrl, ...upbitSetting }));
		else message.warning('업빗 API 키를 입력해주세요.');
	};

	const handleSetBinanceSetting = (values) => {
		coinAPI.setBinanceSetting({ auth, baseUrl, values }).then((response) => {
			if (response) {
				setBinanceSetting(values);
				coinAPI.serverRestart({ auth, baseUrl });
			}
		});
	};

	const handleGetBinanceWallet = () => {
		if (binanceSetting) dispatch(getBinanceWalletAction({ auth, baseUrl, ...binanceSetting }));
		else message.warning('바낸 API 키를 입력해주세요.');
	};

	const handleGetBinanceFuturesWallet = () => {
		if (binanceSetting) dispatch(getBinanceFuturesWalletAction({ auth, baseUrl, ...binanceSetting }));
		else message.warning('바낸 API 키를 입력해주세요.');
	};

	const handleSendFuturesPositionsToTelegramBot = () => {
		coinAPI.getBinanceFuturesPositionsToTelegramBot({ auth, baseUrl, ...binanceSetting });
	};

	const handleSendFuturesTriggersToTelegramBot = () => {
		coinAPI.getBinanceFuturesTriggersToTelegramBot({ auth, baseUrl, ...binanceSetting });
	};

	const handleGetBinanceCoinmWallet = () => {
		if (binanceSetting) dispatch(getBinanceCoinmWalletAction({ auth, baseUrl, ...binanceSetting }));
		else message.warning('바낸 API 키를 입력해주세요.');
	};

	const handleBinanceLeverage = (values) => {
		coinAPI.setBinanceLeverage({ auth, baseUrl, values: { ...binanceSetting, ...values } });
	};

	const handleBinanceMarginType = (values) => {
		coinAPI.setBinanceMarginType({ auth, baseUrl, values: { ...binanceSetting, ...values } });
	};

	const handleBinancePositionSide = () => {
		coinAPI.setBinanceFuturesHedgeMode({ auth, baseUrl, values: binanceSetting });
		coinAPI.setBinanceCoinmHedgeMode({ auth, baseUrl, values: binanceSetting });
	};

	const handleUpbitAddOrders = (values) => {
		coinAPI.setUpbitOrders({ auth, baseUrl, values });
	};

	const handleUpbitAddMarginTriger = (values) => {
		if (values.type === 'race') {
			setUpbitTriggers((prevState) => {
				const _prevState = prevState.filter((x) => x.market !== values.market.cd || x.type !== 'race');
				const triggers = [
					..._prevState,
					{
						market: values.market.cd,
						type: values.type,
						targetProfit: values.targetProfit,
						name: `${values.market.cd.replace('KRW-', '')} 경주마`,
						activate: false
					}
				];
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: triggers });
				return triggers;
			});
		} else {
			setUpbitTriggers((prevState) => {
				const _prevState = prevState.filter((x) => x.market !== values.market.cd || Number(x.tradeQuantity) !== Number(values.tradeQuantity) || x.type === 'race');
				const triggers = [
					..._prevState,
					{
						market: values.market.cd,
						type: values.type,
						bidStep: values.bidStep,
						askStep: values.askStep,
						step: values.market.step,
						pricePrecision: values.market.pricePrecision,
						tradeQuantity: values.tradeQuantity,
						buyQuantity: values.buyQuantity,
						stopBuy: values.stopBuy,
						stopSell: values.stopSell,
						name: `${values.market.cd.replace('KRW-', '')} 자동매매`,
						activate: false
					}
				];
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: triggers });
				return triggers;
			});
		}
	};

	const handelUpbitTrigger = ({ market, activate, tradeQuantity, type }) => {
		if (type !== 'race') {
			setUpbitTriggers((prevState) => {
				const trigger = prevState.find((x) => x.market === market && Number(x.tradeQuantity) === Number(tradeQuantity) && x.type !== 'race');
				const _prevState = prevState.filter((x) => x.market !== market || Number(x.tradeQuantity) !== Number(tradeQuantity) || x.type === 'race');
				trigger.activate = activate;
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: [..._prevState, trigger] });
				return [..._prevState, trigger];
			});
		} else {
			setUpbitTriggers((prevState) => {
				const trigger = prevState.find((x) => x.market === market && x.type === 'race');
				const _prevState = prevState.filter((x) => x.market !== market || x.type !== 'race');
				trigger.activate = activate;
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: [..._prevState, trigger] });
				return [..._prevState, trigger];
			});
		}
	};

	const handelDeleteUpbitTrigger = ({ market, tradeQuantity, type }) => {
		if (type !== 'race') {
			setUpbitTriggers((prevState) => {
				const _prevState = prevState.filter((x) => x.market !== market || Number(x.tradeQuantity) !== Number(tradeQuantity) || x.type === 'race');
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: _prevState });
				return _prevState;
			});
		} else {
			setUpbitTriggers((prevState) => {
				const _prevState = prevState.filter((x) => x.market !== market || x.type !== 'race');
				coinAPI.setUpbitTriggers({ auth, baseUrl, values: _prevState });
				return _prevState;
			});
		}
	};

	const handleBinanceAddOrders = (values) => {
		coinAPI.setBinanceOrders({ auth, baseUrl, values });
	};

	const handleBinanceAddMarginTriger = (values) => {
		setBinanceTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== values.symbol.s || Number(x.tradeQuantity) !== Number(values.tradeQuantity));
			const triggers = [
				..._prevState,
				{
					symbol: values.symbol.s,
					type: values.type,
					buyStep: values.buyStep,
					sellStep: values.sellStep,
					pricePrecision: values.symbol.pricePrecision,
					priceStep: values.symbol.priceStep,
					quantityPrecision: values.symbol.quantityPrecision,
					quantityStep: values.symbol.quantityStep,
					tradeQuantity: values.tradeQuantity,
					buyQuantity: values.buyQuantity,
					stopBuy: values.stopBuy,
					stopSell: values.stopSell,
					name: `${values.symbol.s.replace('USDT', '')} 자동매매`,
					activate: false
				}
			];
			coinAPI.setBinanceTriggers({ auth, baseUrl, values: triggers });
			return triggers;
		});
	};

	const handelBinanceTrigger = ({ symbol, tradeQuantity, activate }) => {
		setBinanceTriggers((prevState) => {
			const trigger = prevState.find((x) => x.symbol === symbol && Number(x.tradeQuantity) === Number(tradeQuantity));
			const _prevState = prevState.filter((x) => x.symbol !== symbol || Number(x.tradeQuantity) !== Number(tradeQuantity));
			coinAPI.setBinanceTriggers({ auth, baseUrl, values: [..._prevState, { ...trigger, activate }] });
			return [..._prevState, { ...trigger, activate }];
		});
	};

	const handelDeleteBinanceTrigger = (symbol, tradeQuantity) => {
		setBinanceTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== symbol || Number(x.tradeQuantity) !== Number(tradeQuantity));
			coinAPI.setBinanceTriggers({ auth, baseUrl, values: _prevState });
			return _prevState;
		});
	};

	const handleBinanceFuturesAddOrders = (values) => {
		coinAPI.setBinanceFuturesOrders({ auth, baseUrl, values });
	};

	const handleBinanceFuturesRePositionOrders = (values) => {
		coinAPI.setBinanceFuturesRePositionOrders({ auth, baseUrl, values });
	};

	const handleBinanceFuturesAddMarginTriger = (values) => {
		setBinanceFuturesTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== values.symbol.s || x.positionSide !== values.positionSide);
			const triggers = [
				..._prevState,
				{
					symbol: values.symbol.s,
					type: values.type,
					positionSide: values.positionSide,
					strategyQuantity: values.strategyQuantity,
					strategyCount: values.strategyCount,
					targetRoe: values.targetRoe,
					strategySupportLevel: values.strategySupportLevel,
					autoReposition: values.autoReposition,
					version: values.version,
					tradeQuantity: values.tradeQuantity,
					tradeCount: values.tradeCount,
					budget: values.budget,
					stopReposition: values.stopReposition,
					pricePrecision: values.symbol.pricePrecision,
					priceStep: values.symbol.priceStep,
					quantityPrecision: values.symbol.quantityPrecision,
					quantityStep: values.symbol.quantityStep,
					name: `${values.symbol.s.replace('USDT', '')} ${values.positionSide}${values.version ? ' (V3)' : ''}`
				}
			];
			coinAPI.setBinanceFuturesTriggers({ auth, baseUrl, values: triggers });
			return triggers;
		});
	};

	const handelBinanceFuturesTrigger = ({ symbol, autoReposition, positionSide }) => {
		setBinanceFuturesTriggers((prevState) => {
			const trigger = prevState.find((x) => x.symbol === symbol && x.positionSide === positionSide);
			const _prevState = prevState.filter((x) => x.symbol !== symbol || x.positionSide !== positionSide);
			trigger.autoReposition = autoReposition;
			coinAPI.setBinanceFuturesTriggers({ auth, baseUrl, values: [..._prevState, trigger] });
			return [..._prevState, trigger];
		});
	};

	const handelDeleteBinanceFuturesTrigger = (symbol, positionSide) => {
		setBinanceFuturesTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== symbol || x.positionSide !== positionSide);
			coinAPI.setBinanceFuturesTriggers({ auth, baseUrl, values: _prevState });
			return _prevState;
		});
	};

	const handleBinanceCoinmAddOrders = (values) => {
		coinAPI.setBinanceCoinmOrders({ auth, baseUrl, values });
	};

	const handleBinanceCoinmRePositionOrders = (values) => {
		coinAPI.setBinanceCoinmRePositionOrders({ auth, baseUrl, values });
	};

	const handleBinanceCoinmAddMarginTriger = (values) => {
		setBinanceCoinmTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== values.symbol.s || x.positionSide !== values.positionSide);
			const triggers = [
				..._prevState,
				{
					symbol: values.symbol.s,
					type: values.type,
					positionSide: values.positionSide,
					targetRoe: values.targetRoe,
					autoReposition: values.autoReposition,
					version: values.version,
					budget: values.budget,
					stopReposition: values.stopReposition,
					pricePrecision: values.symbol.pricePrecision,
					priceStep: values.symbol.priceStep,
					quantityPrecision: values.symbol.quantityPrecision,
					quantityStep: values.symbol.quantityStep,
					name: `${values.symbol.s.replace('USD_PERP', '')} ${values.positionSide}${values.version ? ' (V3)' : ''}`
				}
			];
			coinAPI.setBinanceCoinmTriggers({ auth, baseUrl, values: triggers });
			return triggers;
		});
	};

	const handelBinanceCoinmTrigger = ({ symbol, autoReposition, positionSide }) => {
		setBinanceCoinmTriggers((prevState) => {
			const trigger = prevState.find((x) => x.symbol === symbol && x.positionSide === positionSide);
			const _prevState = prevState.filter((x) => x.symbol !== symbol || x.positionSide !== positionSide);
			trigger.autoReposition = autoReposition;
			coinAPI.setBinanceCoinmTriggers({ auth, baseUrl, values: [..._prevState, trigger] });
			return [..._prevState, trigger];
		});
	};

	const handelDeleteBinanceCoinmTrigger = (symbol, positionSide) => {
		setBinanceCoinmTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.symbol !== symbol || x.positionSide !== positionSide);
			coinAPI.setBinanceCoinmTriggers({ auth, baseUrl, values: _prevState });
			return _prevState;
		});
	};

	const handlePremiumAddOrders = (values) => {
		setPremiumOrders((prevState) => {
			const _prevState = prevState.filter((x) => x.code !== values.code);
			if (!values.order) {
				_prevState.push({
					code: values.code,
					premium: values.premium,
					amount: values.amount,
					upbit: values.upbit.tradeQuantity,
					binance: values.binance.tradeQuantity
				});
			}
			coinAPI.setPremiumOrders({ auth, baseUrl, values: { ...values, orders: _prevState } });
			return _prevState;
		});
	};

	const handelDeletePremiumOrder = (code) => {
		setPremiumOrders((prevState) => {
			const _prevState = prevState.filter((x) => x.code !== code);
			coinAPI.deletePremiumOrder({ auth, baseUrl, values: _prevState });
			return _prevState;
		});
	};

	const handlePremiumAddTriger = (values) => {
		setPremiumTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.code !== values.code);
			const triggers = [..._prevState, values];
			coinAPI.setPremiumTriggers({ auth, baseUrl, values: triggers });
			return triggers;
		});
	};

	const handelDeletePremiumTrigger = (code) => {
		setPremiumTriggers((prevState) => {
			const _prevState = prevState.filter((x) => x.code !== code);
			coinAPI.setPremiumTriggers({ auth, baseUrl, values: _prevState });
			return _prevState;
		});
	};

	const handleSignOut = () => {
		dispatch(signOutAction());
	};

	return (
		<div style={{ maxWidth: '1400px', margin: '0 auto' }}>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" xs={24} xl={16}>
					<Divider orientation="center" style={{ color: '#ff4d4f' }}>
						프리미엄 만료일자: {moment(auth.attributes['custom:expiry']).format('YYYY-MM-DD')} (포인트: {new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(Number(auth.attributes['custom:point']))})
					</Divider>
					<Tabs
						defaultActiveKey="setting"
						// tabBarExtraContent={
						// 	<a href="/how-to-signup" target="_blank">
						// 		User manual
						// 	</a>
						// }
					>
						<TabPane tab="설정" key="setting">
							<Setting
								upbitTicker={upbitTicker}
								binanceTicker={binanceTicker}
								handleSignOut={handleSignOut}
								handleGetExchangeRate={handleGetExchangeRate}
								handleSetExchangeRate={handleSetExchangeRate}
								exchangeRate={exchangeRate}
								handleSetUpbitSetting={handleSetUpbitSetting}
								upbitSetting={upbitSetting}
								handleGetUpbitWallet={handleGetUpbitWallet}
								upbitWallet={upbitWallet}
								symbols={symbols}
								futuresSymbols={futuresSymbols}
								coinmSymbols={coinmSymbols}
								handleSetBinanceSetting={handleSetBinanceSetting}
								binanceSetting={binanceSetting}
								handleGetBinanceWallet={handleGetBinanceWallet}
								binanceWallet={binanceWallet}
								handleGetBinanceFuturesWallet={handleGetBinanceFuturesWallet}
								handleSendFuturesPositionsToTelegramBot={handleSendFuturesPositionsToTelegramBot}
								handleSendFuturesTriggersToTelegramBot={handleSendFuturesTriggersToTelegramBot}
								binanceFuturesWallet={binanceFuturesWallet}
								handleGetBinanceCoinmWallet={handleGetBinanceCoinmWallet}
								binanceCoinmWallet={binanceCoinmWallet}
								handleBinanceLeverage={handleBinanceLeverage}
								handleBinanceMarginType={handleBinanceMarginType}
								handleBinancePositionSide={handleBinancePositionSide}
								baseUrl={baseUrl}
								auth={auth}
							/>
						</TabPane>
						<TabPane tab="업빗" key="upbitOrder">
							<UpbitOrder auth={auth} markets={markets} upbitTicker={upbitTicker} handleUpbitAddOrders={handleUpbitAddOrders} handleUpbitAddMarginTriger={handleUpbitAddMarginTriger} upbitSetting={upbitSetting} baseUrl={baseUrl} triggerValues={upbitTriggerValue} handelUpbitTriggerValue={setUpbitTriggerValue} />
						</TabPane>
						<TabPane tab="김프" key="premiumTable">
							<PremiumTable auth={auth} symbols={futuresSymbols} upbitTicker={upbitTicker} binanceTicker={binanceTicker} exchangeRate={exchangeRate} premiumOrders={premiumOrders} premiumTriggers={premiumTriggers} handlePremiumAddOrders={handlePremiumAddOrders} handelDeletePremiumOrder={handelDeletePremiumOrder} handlePremiumAddTriger={handlePremiumAddTriger} handelDeletePremiumTrigger={handelDeletePremiumTrigger} />
						</TabPane>
						{auth.attributes['custom:binance'] === 'true' && (
							<>
								<TabPane tab="바낸 현물" key="binanceOrder">
									<BinanceOrder symbols={symbols} binanceTicker={binanceTicker} handleBinanceAddOrders={handleBinanceAddOrders} handleBinanceAddMarginTriger={handleBinanceAddMarginTriger} triggerValues={binanceTriggerValue} handelBinanceTriggerValue={setBinanceTriggerValue} />
								</TabPane>
								<TabPane tab="바낸 선물" key="binanceFuturesOrder">
									<BinanceFuturesOrder auth={auth} symbols={futuresSymbols} binanceTicker={binanceTicker} handleBinanceAddOrders={handleBinanceFuturesAddOrders} handleBinanceAddMarginTriger={handleBinanceFuturesAddMarginTriger} triggerValues={binanceFuturesTriggerValue} handelBinanceFuturesTriggerValue={setBinanceFuturesTriggerValue} />
								</TabPane>
								<TabPane tab="바낸 코인엠" key="binanceCoinmOrder">
									<BinanceCoinmOrder auth={auth} symbols={coinmSymbols} binanceTicker={binanceTicker} handleBinanceAddOrders={handleBinanceCoinmAddOrders} handleBinanceAddMarginTriger={handleBinanceCoinmAddMarginTriger} triggerValues={binanceCoinmTriggerValue} handelBinanceCoinmTriggerValue={setBinanceCoinmTriggerValue} />
								</TabPane>
							</>
						)}
						{/* {auth.username === 'young-coin' && (
							<TabPane tab="매매강도" key="tradePower">
								<CoinChart auth={auth} coinAPI={coinAPI} baseUrl={baseUrl} />
							</TabPane>
						)} */}
						{auth.username === 'young-coin' && (
							<TabPane tab="사용자관리" key="user">
								<Admin auth={auth} coinAPI={coinAPI} baseUrl={baseUrl} />
							</TabPane>
						)}
					</Tabs>
				</Col>
				<ScrollCol className="gutter-row" xs={24} xl={8}>
					<Divider orientation="center">조건식</Divider>
					<Tabs defaultActiveKey="upbitTrigger">
						<TabPane tab={`업빗 (${upbitTriggers.length})`} key="upbitTrigger">
							<UpbitTriggers auth={auth} triggers={upbitTriggers} handelTrigger={handelUpbitTrigger} handelDeleteTrigger={handelDeleteUpbitTrigger} handelUpbitTriggerValue={setUpbitTriggerValue} />
						</TabPane>
						<TabPane tab={`바낸 현물 (${binanceTriggers.length})`} key="binanceTrigger">
							<BinanceTriggers auth={auth} triggers={binanceTriggers} handelTrigger={handelBinanceTrigger} handelDeleteTrigger={handelDeleteBinanceTrigger} handelBinanceTriggerValue={setBinanceTriggerValue} />
						</TabPane>
						{auth.attributes['custom:binance'] === 'true' && (
							<>
								<TabPane tab={`바낸 선물(${binanceFuturesTriggers.length})`} key="binanceFuturesTrigger">
									<BinanceFuturesTriggers auth={auth} triggers={binanceFuturesTriggers} handelTrigger={handelBinanceFuturesTrigger} handelRePositionOrders={handleBinanceFuturesRePositionOrders} handelDeleteTrigger={handelDeleteBinanceFuturesTrigger} handelBinanceFuturesTriggerValue={setBinanceFuturesTriggerValue} />
								</TabPane>
								<TabPane tab={`바낸 코인엠(${binanceCoinmTriggers.length})`} key="binanceCoinmTrigger">
									<BinanceCoinmTriggers auth={auth} triggers={binanceCoinmTriggers} handelTrigger={handelBinanceCoinmTrigger} handelRePositionOrders={handleBinanceCoinmRePositionOrders} handelDeleteTrigger={handelDeleteBinanceCoinmTrigger} handelBinanceCoinmTriggerValue={setBinanceCoinmTriggerValue} />
								</TabPane>
							</>
						)}
					</Tabs>
				</ScrollCol>
			</Row>
		</div>
	);
}

export default CoinContainer;
