import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, Switch, Divider } from 'antd';
import { format } from 'd3-format';

import { getBinanceStep } from '../../hooks/useLibrary';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
};

export default ({ auth, symbols, binanceTicker, handleBinanceAddOrders, handleBinanceAddMarginTriger, triggerValues, handelBinanceFuturesTriggerValue }) => {
	const [symbol, setSymbol] = useState({});
	const [orderButtonStyle, setOrderButtonStype] = useState({ background: '#cf304a', borderColor: '#cf304a' });
	const [mode, setMode] = useState(true);

	const [orderForm] = Form.useForm();
	const [marginForm] = Form.useForm();
	const [simpleForm] = Form.useForm();

	useEffect(() => {
		orderForm.resetFields();
		setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		marginForm.resetFields();
		simpleForm.resetFields();
		if (triggerValues) {
			if (triggerValues.type === 'simple') {
				simpleForm.setFieldsValue({
					positionSide: triggerValues.positionSide,
					budget: triggerValues.budget,
					targetRoe: triggerValues.targetRoe,
					autoReposition: triggerValues.autoReposition,
					stopReposition: triggerValues.stopReposition,
					version: triggerValues.version || false
				});
				setMode(true);
			} else {
				marginForm.setFieldsValue({
					positionSide: triggerValues.positionSide,
					strategyQuantity: triggerValues.strategyQuantity,
					strategyCount: triggerValues.strategyCount,
					targetRoe: triggerValues.targetRoe,
					strategySupportLevel: triggerValues.strategySupportLevel,
					autoReposition: triggerValues.autoReposition,
					stopReposition: triggerValues.stopReposition,
					tradeQuantity: triggerValues.tradeQuantity,
					tradeCount: triggerValues.tradeCount,
					totalCoin: Number(Number(triggerValues.strategyQuantity) * Number(triggerValues.strategyCount)).toFixed(symbol.quantityPrecision)
				});
				setMode(false);
			}
		}
	}, [symbol]);

	useEffect(() => {
		if (triggerValues) {
			handleSymbol(triggerValues.symbol, false);
		}
	}, [triggerValues]);

	const handleSymbol = (value, triggerFlag) => {
		if (triggerFlag) {
			handelBinanceFuturesTriggerValue(null);
		}
		const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(symbols.find((x) => x.symbol === value));
		setSymbol({ s: value, c: binanceTicker[value], priceStep, pricePrecision, quantityStep, quantityPrecision });
	};

	const handleOrderSide = (value) => {
		if (value === 'SELL') {
			setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		} else {
			setOrderButtonStype({ background: '#03a66d', borderColor: '#03a66d' });
		}
	};

	const handelOrderForm = (value, values) => {
		if (symbol.s) {
			let resourceUSD = 0;
			for (let i = 0; i < values.orderCount; i++) {
				resourceUSD += (values.tradeStartPrice - values.tradeStep * i) * Number(values.tradeQuantity);
			}
			orderForm.setFieldsValue({
				tradeEndPrice: `$${Number(Number(values.tradeStartPrice + (values.orderCount - 1) * values.tradeStep * (values.side === 'SELL' ? 1 : -1)).toFixed(symbol.pricePrecision))}`,
				resource: values.side === 'SELL' ? `${Number(values.tradeQuantity) * values.orderCount} ${symbol.s ? symbol.s.replace('USDT', '') : '코인'}` : format(`$,.${symbol.pricePrecision}f`)(resourceUSD)
			});
		}
	};

	const handelTriggerForm = (value, values) => {
		if (symbol.s) {
			const immediateBuy = Math.round(Number(values.strategyCount) * 0.1);
			const orderCount = Math.round(Number(values.strategyCount) - immediateBuy);
			const step = (values.positionSide === 'LONG' ? ((symbol && Number(symbol.c)) || 1) - Number(values.strategySupportLevel) : Number(values.strategySupportLevel) - ((symbol && Number(symbol.c)) || 1)) / orderCount;

			if (step > 0) {
				let strategyCost = Number(values.strategyQuantity) * immediateBuy * ((symbol && Number(symbol.c)) || 1);
				let sftCost = 0;

				for (let i = 1; i <= orderCount; i++) {
					strategyCost += (((symbol && Number(symbol.c)) || 1) + step * i * (values.positionSide === 'LONG' ? -1 : 1)) * Number(values.strategyQuantity);
				}

				if (Number(values.tradeQuantity)) {
					const sftStep = (values.positionSide === 'LONG' ? ((symbol && Number(symbol.c)) || 1) - Number(values.strategySupportLevel) : Number(values.strategySupportLevel) - ((symbol && Number(symbol.c)) || 1)) / values.tradeCount;

					for (let i = 0; i < values.tradeCount; i++) {
						sftCost += (((symbol && Number(symbol.c)) || 1) + sftStep * i * (values.positionSide === 'LONG' ? -1 : 1)) * Number(values.tradeQuantity);
					}
				}

				marginForm.setFieldsValue({
					totalCoin: Number(Number(values.strategyQuantity) * Number(values.strategyCount)).toFixed((symbol && symbol.quantityPrecision) || 0),
					strategyCost: strategyCost.toFixed(2),
					sftCost: sftCost.toFixed(2)
				});

				if (value.strategySupportLevel) {
					marginForm.setFieldsValue({
						strategyQuantity: (Math.ceil(5 / (values.positionSide === 'LONG' ? value.strategySupportLevel : Number(symbol.c)) / symbol.quantityStep) * symbol.quantityStep).toFixed(symbol.quantityPrecision)
					});
				}
			}
		}
	};

	return (
		<>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col span={24}>
					<Form {...layout} name="binance_setting" initialValues={{ symbol: symbol.s }}>
						<Form.Item name="symbol" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={symbol.s} onChange={(e) => handleSymbol(e, true)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										symbols &&
										symbols.map((item, i) => (
											<Select.Option key={item.symbol} value={item.symbol}>
												{item.symbol.replace('USDT', '')}
											</Select.Option>
										)),
									[symbols]
								)}
							</Select>
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="일괄 주문" size="small" style={{ width: '100%', backgroundColor: '#fff1b8' }}>
							<Form {...layout} form={orderForm} initialValues={{ symbol: symbol.s, positionSide: 'LONG', side: 'BUY', tradeQuantity: 100, orderCount: 1, tradeStartPrice: (symbol && Number(symbol.c)) || 0, tradeStep: (symbol && symbol.priceStep) || 1 }} onValuesChange={(e, allValues) => handelOrderForm(e, allValues)} onFinish={(e) => handleBinanceAddOrders({ ...e, symbol })}>
								<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item label="포지션 구분" name="positionSide">
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="LONG">롱</Radio.Button>
										<Radio.Button value="SHORT">숏</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item label="주문 구분" name="side" onChange={(e) => handleOrderSide(e.target.value)}>
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="SELL">매도</Radio.Button>
										<Radio.Button value="BUY">매수</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									name="tradeQuantity"
									label="주문당 코인수"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '코인수를 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
								</Form.Item>
								<Form.Item name="tradeStartPrice" label="시작가격">
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="tradeEndPrice" label="종료가격">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item name="tradeStep" label="주문간 가격차">
									<InputNumber style={{ width: 150 }} min={0} max={100000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="orderCount" label="주문수">
									<InputNumber min={1} max={500} step={1} />
								</Form.Item>
								<Form.Item name="resource" label="비용">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={orderButtonStyle}>
										주문
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="조건식 추가" size="small" style={{ width: '100%', backgroundColor: '#fff1b8' }} extra={<Switch style={{ marginRight: '8px' }} checked={mode} checkedChildren="간편" unCheckedChildren="고급" onChange={(e) => setMode(e)} />}>
							<div style={{ display: mode ? 'none' : undefined }}>
								<Form {...layout} form={marginForm} initialValues={{ symbol: symbol.s, positionSide: 'LONG', strategyQuantity: 0, strategyCount: 100, targetRoe: 0.01, strategySupportLevel: (symbol && Number(symbol.c)) || 1, stopReposition: 0, autoReposition: true, tradeQuantity: 0, tradeCount: 2, totalCoin: 0, strategyCost: 0, sftCost: 0 }} onValuesChange={(e, allValues) => handelTriggerForm(e, allValues)} onFinish={(e) => handleBinanceAddMarginTriger({ ...e, symbol, type: 'one way' })}>
									<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
										<Input disabled />
									</Form.Item>
									<Form.Item label="포지션 구분" name="positionSide">
										<Radio.Group buttonStyle="solid">
											<Radio.Button value="LONG">롱</Radio.Button>
											<Radio.Button value="SHORT">숏</Radio.Button>
										</Radio.Group>
									</Form.Item>
									<Divider plain>분할 매수</Divider>
									<Form.Item
										name="strategyQuantity"
										label="주문당 코인수"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '코인수를 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
									</Form.Item>
									<Form.Item label="주문수" style={{ marginBottom: 0 }}>
										<Form.Item
											name="strategyCount"
											style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
											rules={[
												{
													validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
												},
												{
													required: true,
													message: '주문수를 입력해주세요.'
												}
											]}
										>
											<InputNumber min={1} max={500} step={1} />
										</Form.Item>
										<Form.Item name="totalCoin" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
											<Input suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} disabled />
										</Form.Item>
									</Form.Item>
									<Form.Item label="목표 수익률" style={{ marginBottom: 0 }}>
										<Form.Item
											name="targetRoe"
											style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
											rules={[
												{
													validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
												},
												{
													required: true,
													message: '목표 수익률을 입력하세요.'
												}
											]}
										>
											<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
										</Form.Item>
										<Form.Item name="strategyCost" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
											<Input suffix="USDT" disabled />
										</Form.Item>
									</Form.Item>
									<Form.Item
										name="strategySupportLevel"
										label="지지선"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '지지선을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
									</Form.Item>
									<Form.Item
										name="stopReposition"
										label="재진입 제한선"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '재진입 제한선을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
									</Form.Item>
									<Form.Item name="autoReposition" label="재진입" valuePropName="checked">
										<Switch checkedChildren="자동" unCheckedChildren="수동" />
									</Form.Item>
									<Divider plain>별동대</Divider>
									<Form.Item
										name="tradeQuantity"
										label="별동대당 코인수"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '별동대당 코인수를 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
									</Form.Item>
									<Form.Item label="별동대 주문수" style={{ marginBottom: 0 }}>
										<Form.Item
											name="tradeCount"
											style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
											rules={[
												{
													validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
												},
												{
													required: true,
													message: '별동대 주문수를 입력해주세요.'
												}
											]}
										>
											<InputNumber min={1} max={500} step={1} />
										</Form.Item>
										<Form.Item name="sftCost" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
											<Input suffix="USDT" disabled />
										</Form.Item>
									</Form.Item>
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
											추가(변경)
										</Button>
										{/* <Button htmlType="button" style={{ color: '#1f1f1f', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handleBinanceAddMarginTriger({ ...marginForm.getFieldsValue(true), symbol, type: 'one way' })}>
									One way
								</Button> */}
									</Form.Item>
								</Form>
							</div>
							<div style={{ display: mode ? undefined : 'none' }}>
								<Form {...layout} form={simpleForm} initialValues={{ symbol: symbol.s, positionSide: 'LONG', budget: 1000, targetRoe: 0.01, stopReposition: 0, autoReposition: true, version: false }} onFinish={(e) => handleBinanceAddMarginTriger({ ...e, symbol, type: 'simple' })}>
									<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
										<Input disabled />
									</Form.Item>
									<Form.Item label="포지션 구분" name="positionSide">
										<Radio.Group buttonStyle="solid">
											<Radio.Button value="LONG">롱</Radio.Button>
											<Radio.Button value="SHORT">숏</Radio.Button>
										</Radio.Group>
									</Form.Item>
									<Form.Item
										label="예산"
										name="budget"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
											},
											{
												required: true,
												message: '예산을 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix="USDT" />
									</Form.Item>
									<Form.Item
										label="목표 수익률"
										name="targetRoe"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '목표 수익률을 입력하세요.'
											}
										]}
									>
										<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
									</Form.Item>
									<Form.Item
										name="stopReposition"
										label="재진입 제한선"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '재진입 제한선을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
									</Form.Item>
									<Form.Item name="autoReposition" label="재진입" valuePropName="checked">
										<Switch checkedChildren="자동" unCheckedChildren="수동" />
									</Form.Item>
									{auth.attributes['custom:version'] !== 'v2' && (
										<Form.Item name="version" label="버전" valuePropName="checked">
											<Switch checkedChildren="V3" unCheckedChildren="V2" />
										</Form.Item>
									)}
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
											추가(변경)
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Card>
					</Space>
				</Col>
			</Row>
		</>
	);
};
