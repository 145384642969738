import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, message, Descriptions } from 'antd';
import { format } from 'd3-format';

import { getBinanceStep } from '../../hooks/useLibrary';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
};

export default ({ symbols, binanceTicker, handleBinanceAddOrders, handleBinanceAddMarginTriger }) => {
	const [symbol, setSymbol] = useState({});
	const [orderButtonStyle, setOrderButtonStype] = useState({ background: '#cf304a', borderColor: '#cf304a' });

	const [orderForm] = Form.useForm();
	const [marginForm] = Form.useForm();

	useEffect(() => {
		orderForm.resetFields();
		setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		marginForm.resetFields();
	}, [symbol]);

	const handleSymbol = (value) => {
		const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(symbols.find((x) => x.symbol === value));
		setSymbol({ s: value, c: binanceTicker[value], priceStep, pricePrecision, quantityStep, quantityPrecision });
	};

	const handleOrderSide = (value) => {
		if (value === 'SELL') {
			setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		} else {
			setOrderButtonStype({ background: '#03a66d', borderColor: '#03a66d' });
		}
	};

	const handelOrderForm = (value, values) => {
		if (symbol.s) {
			let resourceUSD = 0;
			for (let i = 0; i < values.orderCount; i++) {
				resourceUSD += (values.tradeStartPrice - values.tradeStep * i) * Number(values.tradeQuantity);
			}
			orderForm.setFieldsValue({ tradeEndPrice: `$${Number(Number(values.tradeStartPrice + (values.orderCount - 1) * values.tradeStep * (values.side === 'SELL' ? 1 : -1)).toFixed(symbol.pricePrecision))}` });
			orderForm.setFieldsValue({ resource: values.side === 'SELL' ? `${Number(values.tradeQuantity) * values.orderCount} ${symbol.s ? symbol.s.replace('USDT', '') : '코인'}` : format(`$,.${symbol.pricePrecision}f`)(resourceUSD) });
		}
	};

	return (
		<>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col span={24}>
					<Form {...layout} name="binance_setting" initialValues={{ symbol: symbol.s }}>
						<Form.Item name="symbol" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={symbol.s} onChange={handleSymbol} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										symbols &&
										symbols.map((item, i) => (
											<Select.Option key={item.symbol} value={item.symbol}>
												{item.symbol.replace('USDT', '')}
											</Select.Option>
										)),
									[symbols]
								)}
							</Select>
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="일괄 주문" size="small" style={{ width: '100%', backgroundColor: '#eaff8f' }}>
							<Form {...layout} form={orderForm} initialValues={{ symbol: symbol.s, side: 'BUY', tradeQuantity: 100, orderCount: 1, tradeStartPrice: (symbol && Number(symbol.c)) || 0, tradeStep: (symbol && symbol.priceStep) || 1 }} onValuesChange={(e, allValues) => handelOrderForm(e, allValues)} onFinish={(e) => handleBinanceAddOrders({ ...e, symbol })}>
								<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item label="주문 구분" name="side" onChange={(e) => handleOrderSide(e.target.value)}>
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="SELL">매도</Radio.Button>
										<Radio.Button value="BUY">매수</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									name="tradeQuantity"
									label="주문당 코인수"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '코인수를 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
								</Form.Item>
								<Form.Item name="tradeStartPrice" label="시작가격">
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="tradeEndPrice" label="종료가격">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item name="tradeStep" label="주문간 가격차">
									<InputNumber style={{ width: 150 }} min={0} max={100000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="orderCount" label="주문수">
									<InputNumber min={1} max={500} step={1} />
								</Form.Item>
								<Form.Item name="resource" label="비용">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={orderButtonStyle}>
										주문
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="조건식 추가" size="small" style={{ width: '100%', backgroundColor: '#eaff8f' }}>
							<Form {...layout} form={marginForm} initialValues={{ symbol: symbol.s, tradeQuantity: 100, buyQuantity: 100, dummyQuantity: 100, buyStep: 0, sellStep: 0, stopBuy: 0, stopSell: 0 }} onFinish={(e) => handleBinanceAddMarginTriger({ ...e, symbol, type: 'margin' })}>
								<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item
									name="tradeQuantity"
									label="매도 수량"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매도 수량을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
								</Form.Item>
								<Form.Item
									name="buyQuantity"
									label="매수 수량"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매수 수량을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={symbol.s ? symbol.s.replace('USDT', '') : '코인'} />
								</Form.Item>
								<Form.Item
									name="sellStep"
									label="매도가 증가폭"
									rules={[
										{
											validator: (_, value) => (isNaN(value) || Number(value) === 0 ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매도가 증가폭을 입력해주세요.'
										}
									]}
								>
									<InputNumber style={{ width: 150 }} min={0} max={1000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item
									name="buyStep"
									label="매수가 감소폭"
									rules={[
										{
											validator: (_, value) => (isNaN(value) || Number(value) === 0 ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매수가 감소폭을 입력해주세요.'
										}
									]}
								>
									<InputNumber style={{ width: 150 }} min={0} max={1000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item
									name="stopBuy"
									label="매수 상한선"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매수 상한선을 입력해주세요.'
										}
									]}
								>
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item
									name="stopSell"
									label="매도 하한선"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '매도 하한선을 입력해주세요.'
										}
									]}
								>
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
										추가(변경)
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
			</Row>
		</>
	);
};
